<template>
  <div>
    <genetic-distance-ancient-help />
    <b-row class="mt-1">
      <b-col cols="12" xl="8" md="7"  class="mt-2">
        <div class="number-card">
          <div class="number-card-header">
            <h4 class="genetice-dist-text"> {{ $t('Number Of Populations') }}</h4>
            <div class="lab-timeline-switch">
              <div class="timeline-item" @click="switchLeft()">
                <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_left.svg'" />
              </div>
              <div class="timeline-item last"  @click="switchRight()">
                <b-img :src="'https://api.illustrativedna.com/assets/icons/time_line_right.svg'" />
              </div>
            </div>
          </div>
          <div class="number-card-body row">
            <div class="col-md-12">
              <vue-slider
                v-model="$store.state.ancientpopulation.closestAncientPopNumber"
                :adsorb="true"
                :data="sliderData"
                :marks="true"
                @change="onChangeClosestAncientPop"
                direction='ltr'
                class="genetic-dist-slider"
              />
            </div>
          </div>
        </div>

        <div class="map-card mt-1">
          <ancient-distance-map v-if="closestAncientPopImageResults != null && showMap" />
        </div>
      </b-col>
      <b-col cols="12" xl="4" md="5"  class="mt-2">
        <div class="ancient-genetic-distance">
          <div class="ancient-header">
            <div class="ancient-header-title">
              <h4>Closest Ancient Samples</h4>
            </div>
          </div>
         
          <div class="ancient-body cs-scroll-style">
            <div 
                v-for="(ancientItem) in closestAncientPopImageResults"
                :key="ancientItem.title" @click="openAncientDetail(ancientItem)">
              <div class="ancient-item">
                <div class="text-avatar">
                  <b-media-aside class="mr-75">
                    <b-avatar
                      rounded
                      size="60"
                      :src="ancientItem.pictureUrl"
                    />
                  </b-media-aside>
                  <div class="text-pop">
                    <h5 class="genetice-dist-text">
                      {{ ancientItem.title.replaceAll('*', ' ') }}
                    </h5>
                    <small>{{ ancientItem.date }}</small>
                  </div>
                </div>
                <div class="distance">
                  <span class="mr-75" :style="{ color: ancientItem.gradHSL }">{{ ancientItem.distance }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <AncientDetailPage v-if="modalObject.showModal" :modalObject="modalObject" :variant="'genetic-dist-ancient'"/>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import Ripple from 'vue-ripple-directive'

/* eslint-disable global-require */
import AncientDetailPage from '../../pages/AncientDetailPage.vue';
import GeneticDistanceAncientHelp from './helps/GeneticDistanceAncientHelp.vue';
import AncientDistanceMap from './maps/AncientDistanceMap.vue';

export default {
  components: {
    VueSlider,
    AncientDetailPage,
    GeneticDistanceAncientHelp,
    AncientDistanceMap,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      sliderData: ['10', '20', '30', '40', '50'],
      showMap: true,

      modalObject: {
        item: {
          title: '',
          id: null,
        },
        showModal: false,
        isMixedMode: false,
      },

      itemView: 'list-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
    }
  },
  methods: {
    onInitClosestAncientPop() {
      this.$store.dispatch('ancientpopulation/fetchAncientSourceDatas', { sourceDataType: 3 })
        .then(() => {
          this.$store.commit('ancientpopulation/runAncientDist');
          this.OnRunAncientProx();
        })
    },
    onChangeClosestAncientPop() {
      if (this.$store.state.ancientpopulation.ancientSourceDatas.length) {
        this.$store.commit('ancientpopulation/setAncientSourceDatas', [])
        this.$store.commit('ancientpopulation/runAncientDist');
        this.OnRunAncientProx();
      } else {
        this.$store.dispatch('ancientpopulation/fetchAncientSourceDatas', { sourceDataType: 3 })
          .then(() => {
            this.$store.commit('ancientpopulation/runAncientDist');
            this.OnRunAncientProx();
          })
      }
    },
    OnRunAncientProx() {
      this.showMap = false;
      const results = this.$store.getters['ancientpopulation/getClosestAncientPopResults'];
      const resultDistance = results.map(item => item.title);
      this.$store.dispatch('operation/fetchAncientProxImageDatas', { listOfSourceData: resultDistance })
          .then(response => {
            if (response.statusCode === 200) {
              const imageResult = results.map(item => {
                const findDistItem = response.result.find(x => x.sourceCode === item.title);
                if (findDistItem) {
                  item.pictureUrl = findDistItem.pictureUrl;
                  item.id = findDistItem.id;
                  item.date = findDistItem.date;
                }

                return item;
              });
              
              this.$store.commit('ancientpopulation/setClosestAncientImageResults', imageResult);
              this.showMap = true;
            }
          })
    },
    openAncientDetail(ancientItem) {
      if (ancientItem.id) {
        this.modalObject.item.id = ancientItem.id;
        this.modalObject.item.title = ancientItem.title;
        this.modalObject.showModal = true;
      }
    },

    switchLeft() {
      /* eslint-disable */
      if (this.$store.state.ancientpopulation.closestAncientPopNumber != '10') {
        this.$store.state.ancientpopulation.closestAncientPopNumber = (parseInt(this.$store.state.ancientpopulation.closestAncientPopNumber) - 10).toString();
        this.onChangeClosestAncientPop();
      }
    },
    switchRight() {
      /* eslint-disable */
      if (this.$store.state.ancientpopulation.closestAncientPopNumber != '50') {
        this.$store.state.ancientpopulation.closestAncientPopNumber = (parseInt(this.$store.state.ancientpopulation.closestAncientPopNumber) + 10).toString();
        this.onChangeClosestAncientPop();
      }
    },
  },
  computed: {
    closestAncientPopImageResults() {
      return this.$store.getters['ancientpopulation/getClosestAncientPopImageResults'];
    },
  },
  mounted() {
    this.$root.$refs.AncientGeneticDistance = this;
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-slider.scss';
.genetic-dist-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-genetic-dist-color) !important;
}

.genetic-dist-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-genetic-dist-color) !important;
  border-color: var(--lab-g25-genetic-dist-color) !important;
}
.genetic-dist-slider .vue-slider-dot-handle {
  border: 2px solid var(--lab-g25-genetic-dist-color) !important;

  &-focus {
    box-shadow: 0px 0px 1px 2px #e27d955c !important;
  }
}

.genetic-dist-slider .vue-slider-process {
  background-color: var(--lab-g25-genetic-dist-color) !important;
}

.genetic-dist-slider .vue-slider-rail {
  background-color:  #e27d955c !important;
}

.genetic-dist-slider .vue-slider-dot-tooltip-inner {
  background-color: var(--lab-g25-genetic-dist-color) !important;
  border-color: var(--lab-g25-genetic-dist-color) !important;
}
</style>

<style lang="scss" scoped>
.map-card {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  padding: 1.1rem;
  border-radius: 10px;
}

.ancient-genetic-distance {
  background: var(--bg-card-color);
  box-shadow: var(--lab-card-shadow);
  border-radius: 10px;
  max-width: 800px;
  margin: auto;

  .ancient-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 2rem 0 1rem 0;

    .ancient-header-title {
      display: flex;
      align-items: center;
      padding: 0 2rem;
      h4 {
        color: var(--lab-carbon-fiber);
        font-weight: 600;
        font-size: 1rem;
        margin: 0;
      }
    }
    .ancient-header-title::before { 
      content: '';
      margin-right: 5px;
      height: 20px;
      width: 7px;
      background-color: var(--lab-g25-genetic-dist-color);
    }
  }

  .ancient-body {
    padding: 0.5rem 1.5rem;
    max-height: 700px;
    overflow: auto !important;
    overflow-x: hidden !important;
  }

  .text-number {
    font-weight: bold;
    font-size: 1rem;
    color: var(--lab-text-color-title-02);
  }
  .ancient-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 20px;

    .text-avatar {
      display: flex;
      align-items: center;
    }

    h5 {
      font-weight: bold;
      font-size: 0.8rem;
      color: var(--lab-text-color-title-02)
    }
    small {
      font-weight: bold;
      font-size: 0.65rem;
    }
    .distance {
      font-weight: bold;
      font-size: 0.8rem;
      color: var(--lab-text-color-title-02)
    }

    .b-avatar {
      transition: transform .35s !important;
    }
  }

  .ancient-item:hover .genetice-dist-text {
    color: var(--lab-g25-genetic-dist-color);
  }
  .ancient-item:hover .b-avatar {
      transform: scale(1.25) !important;
  }
}

hr {
  margin-top: 0.3rem;
}

.lab-timeline-switch {
  display: flex;
  align-items: center;
  justify-content: end;
  
  .timeline-item {
    background-color: var(--lab-g25-genetic-dist-color) !important;
  }
}

.dark-layout  {
 .ancient-genetic-distance .ancient-header .ancient-header-title h4 {
    color: var(--lab-g25-genetic-dist-color);
 }
}
</style>
