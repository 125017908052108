<template>
  <!-- select 2 demo -->
  <div>
    <b-modal
      ref="modal-ancient-detail"
      :title="modalObject.item.title.replaceAll('*', ' ')"
      hide-footer
      scrollable
      cancel-variant="outline-secondary"
      v-if="modalObject.item"
      @hidden="hiddenModal"
      size="lg"
      :content-class="variant"
    >
      <b-row v-if="ancientProxDetail !== null">
        <b-col cols="12" xl="6" md="6">
          <div class="image-container">
            <b-img
              class="mx-auto detail-image"
              :src="ancientProxDetail.pictureUrl"
            />
          </div>
          <!-- <b-card>
            <b-img
              fluid
              class="mb-2"
              :src="ancientProxDetail.pictureUrl"
            />
            
            <b-card-title>{{ ancientProxDetail.title }}</b-card-title>
            <b-card-text v-html="ancientProxDetail.desciption1"></b-card-text>
          </b-card> -->
        </b-col>

        <b-col cols="12" xl="6" md="6">
          <div class="breakdown-container">
            <div class="title-header">
              <h4>{{ $t('Genetically Closest Populations') }}</h4>
            </div>
            <hr />

            <div class="breakdown-body text-font-size">
              <b-table
                :fields="fields"
                :items="distanceResult"
                responsive="sm"
                head-variant="light"
                small
                bordered
                outlined
              >

                <template #cell(title)="data">
                  <span> 
                    {{ data.item.title.replaceAll('*', ' ') }}
                  </span>
                </template>
                <template #cell(distance)="data">
                  <span> {{ data.item.distance }}</span>
                </template>
              </b-table>
            </div>
          </div>
        </b-col>
        
        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ 'Description' }}</h4>
          </div>
          <hr />
          <div class="p-2 text-font-size">
            <b-card-text v-html="ancientProxDetail.desciption1"></b-card-text>
          </div>
        </b-col>

        <b-col cols="12" xl="12" md="12" class="mt-2">
          <div class="title-header">
            <h4>{{ 'Meta' }}</h4>
          </div>
          <hr />
          <div class="p-2 text-font-size">
            <b-card-text class="" >
                <feather-icon icon="CalendarIcon" size="18" class="date" color= "#fa7d09"/> <b style="color: #fa7d09">Date:</b> {{ancientProxDetail.date}}
            </b-card-text>
            <b-card-text class="mt-2">
                <svg height="18pt" class="ext-primary" viewBox="0 0 384 384" width="14pt" xmlns="http://www.w3.org/2000/svg"><path fill="#00ADB5" d="m383.792969 13.9375c-.175781-1.378906-.480469-2.707031-.984375-3.953125-.015625-.03125-.015625-.074219-.023438-.113281 0-.007813-.007812-.015625-.015625-.023438-.554687-1.3125-1.3125-2.503906-2.167969-3.609375-.210937-.261719-.417968-.519531-.640624-.765625-.914063-1.03125-1.90625-1.984375-3.058594-2.761718-.03125-.023438-.070313-.03125-.101563-.054688-1.113281-.734375-2.34375-1.289062-3.632812-1.726562-.320313-.113282-.632813-.210938-.960938-.296876-1.351562-.367187-2.742187-.632812-4.207031-.632812h-112c-8.832031 0-16 7.167969-16 16s7.167969 16 16 16h73.367188l-95.496094 95.496094c-25.464844-20.367188-56.816406-31.496094-89.871094-31.496094-79.398438 0-144 64.601562-144 144s64.601562 144 144 144 144-64.601562 144-144c0-33.039062-11.121094-64.382812-31.503906-89.871094l95.503906-95.503906v73.375c0 8.832031 7.167969 16 16 16s16-7.167969 16-16v-112c0-.335938-.078125-.65625-.097656-.984375-.023438-.367187-.0625-.71875-.109375-1.078125zm-239.792969 338.0625c-61.761719 0-112-50.238281-112-112s50.238281-112 112-112c29.902344 0 58.054688 11.640625 79.222656 32.734375 21.136719 21.210937 32.777344 49.363281 32.777344 79.265625 0 61.761719-50.238281 112-112 112zm0 0"/></svg> 
                <b style="color: #00ADB5">Y-DNA:</b> {{ancientProxDetail.yDna}}
            </b-card-text>
            <b-card-text class="mt-2">
                <svg height="18pt" class="ext-primary" viewBox="0 0 512 512" width="16pt" xmlns="http://www.w3.org/2000/svg">
                <path style="fill:#f76a8c;" d="M318.841,402.801h-30.905v46.358h30.905c12.801,0,23.179-10.378,23.179-23.179
                  S331.642,402.801,318.841,402.801z"/>
                <path style="fill:#f76a8c;" d="M425.98,169.98C425.98,76.103,349.877,0,256,0S86.02,76.103,86.02,169.98
                  c0,86.014,63.891,157.093,146.801,168.401v64.42h-39.662c-12.801,0-23.179,10.378-23.179,23.179s10.378,23.179,23.179,23.179h39.662
                  v39.662C232.821,501.622,243.199,512,256,512c12.801,0,23.179-10.378,23.179-23.179v-39.662h8.757
                  c12.801,0,23.179-10.378,23.179-23.179s-10.378-23.179-23.179-23.179h-8.757v-64.42C362.089,327.073,425.98,255.994,425.98,169.98z
                  M256,293.602c-68.274,0-123.622-55.348-123.622-123.622S187.726,46.358,256,46.358s123.622,55.348,123.622,123.622
                  S324.274,293.602,256,293.602z"/>
                </svg> <b style="color: #f76a8c">mtDNA:</b> {{ancientProxDetail.mtDna}}
            </b-card-text>
            <b-card-text class="mt-2">
                <feather-icon icon="MapPinIcon" size="18" class="location" color= "#8B5E83"/> <b style="color: #8B5E83">Location:</b> {{ancientProxDetail.location}}
            </b-card-text>
            <b-card-text class="mt-2">
                <feather-icon icon="SearchIcon" size="18" class="research" color="#61B15A"/> <b style="color: #61B15A">Research:</b> <a :href="researchArray[0]" class="researchlink" target="_blank">See</a>
            </b-card-text>
            <b-card-text class="mt-2" v-if="researchArray[1]">
                <feather-icon icon="SearchIcon" size="18" class="research" color="#61B15A"/> <b style="color: #61B15A">Research 2:</b> <a :href="researchArray[1]" class="researchlink" target="_blank">See</a>
            </b-card-text>
            <b-card-text class="mt-2" v-if="researchArray[2]">
                <feather-icon icon="SearchIcon" size="18" class="research" color="#21B15A"/> <b style="color: #61B15A">Research 3:</b> <a :href="researchArray[6]" class="researchlink" target="_blank">See</a>
            </b-card-text>
            <b-card-text class="mt-2" v-if="researchArray[3]">
                <feather-icon icon="SearchIcon" size="18" class="research" color="#61B15A"/> <b style="color: #61B15A">Research 4:</b> <a :href="researchArray[3]" class="researchlink" target="_blank">See</a>
            </b-card-text>
            <b-card-text class="mt-2">
                <feather-icon icon="DatabaseIcon" size="18" class="research" color="#FFB129"/> <b style="color: #FFB129">Sample(s):</b> {{ancientProxDetail.samples}}
            </b-card-text>
          </div>

        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/common/api.service'
import AlertService from '@/common/alert.service'
import IllusDnaService from '@/common/custom-service/IllusDnaService'

export default {
  components: {
  },
  props: {
    modalObject: {
      type: Object,
    },
    callback: {
      type: Function,
      default() {
        return {}
      },
    },
    variant: {
      type: String,
    },
  },
  data() {
    return {
      ancientProxDetail: null,
      researchArray: [],

      distanceResult: [],
      fields: [
        {
          // A virtual column with custom formatter
          key: 'distance',
          label: 'distance',
        },
        {
          // A regular column with custom formatter
          key: 'title',
          label: 'Modern Population',
        },
      ],
    }
  },
  methods: {
    initialModal() {
      if (!this.modalObject.isMixedMode) {
        const target = this.$store.state.ancientpopulation.ancientSourceDatasResult.filter(x => x[0] === this.modalObject.item.title);
        this.onCalculateModernDist(target);
      } else {
        this.getMixedModeCoordinate();
      }
      
      this.$store.dispatch('operation/fetchAncientProxDetailDatas', { id: this.modalObject.item.id })
        .then(response => {
          if (response.statusCode === 200) {
            this.ancientProxDetail = response.result;
            if (this.ancientProxDetail.research !== null) {
              this.researchArray = this.ancientProxDetail.research.split('\n');
            }
          } else {
            AlertService.error(this, response.message)
          }
        });
      this.$refs['modal-ancient-detail'].show();
    },
    hiddenModal() {
      this.$refs['modal-ancient-detail'].hide()
      this.modalObject.showModal = false;
    },

    getMixedModeCoordinate() {
      const query = { 
        sourceCode: this.modalObject.item.title,
        dataVersion: this.$store.getters['orderResult/getDataVersion'],
      }
      ApiService.postSilent('sourcedata/getmixedmode', query, this)
        .then(response => {
          if (response.statusCode === 200) {
            const target = IllusDnaService.codeDataToArray(response.result);
            this.onCalculateModernDist(target.result);
          }
        });
    },

    onCalculateModernDist(target) {
      IllusDnaService.dataArrays.targetCodeArray = target;
      IllusDnaService.dataArrays.targetCodeNumber = target.length;
      IllusDnaService.dataObject.maxDistOutPut = 10;
      this.onSetModernSourceData(() => {
        this.distanceResult = IllusDnaService.calculateDistance(0);

        const resolveSource = IllusDnaService.codeDataToArray(this.$store.state.orderResult.selectedTarget);
        if (resolveSource.isSuccess) {
          IllusDnaService.onSetTarget(resolveSource.result);
        }
      });
    },
    onSetModernSourceData(callback) {
      if (this.$store.state.modernpopulation.modernSourceDatas.length) {
        this.$store.commit('modernpopulation/setModernSourceDatas', []);
        if (typeof callback === 'function') {
          callback();
        }
      } else {
        this.$store.dispatch('modernpopulation/fetchModernSourceDatasSilent', { sourceDataType: 2 })
          .then(() => {
            if (typeof callback === 'function') {
              callback();
            }
          })
      }
    },
  },
  mounted() {
    this.initialModal()
  },
};
</script>

<style lang="scss">
.mixed-mode-ancient {
  .modal-header::before {
    background-color: var(--lab-g25-unsupervised-color) !important;
  }
  .modal-header .close {
    background-color: #ffb1294f !important;
    color: var(--lab-g25-unsupervised-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-unsupervised-color) !important;
  }
}

.dark-layout .mixed-mode-ancient {
  .modal-header h5 {
    color: var(--lab-g25-unsupervised-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-unsupervised-color) !important;
  }
}

.genetic-dist-ancient {
  .modal-header::before {
    background-color: var(--lab-g25-genetic-dist-color) !important;
  }
  .modal-header .close {
    background-color: #ffb1294f !important;
    color: var(--lab-g25-genetic-dist-color) !important;
  }
  .title-header::before {
    background-color: var(--lab-g25-genetic-dist-color) !important;
  }
}

.dark-layout .genetic-dist-ancient {
  .modal-header h5 {
    color: var(--lab-g25-genetic-dist-color) !important;
  }
  .title-header h4 {
    color: var(--lab-g25-genetic-dist-color) !important;
  }
}

</style>

<style  lang="scss" scoped>

.image-container {
  padding: 1rem 0.5rem;
  border-radius: 10px;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 260px !important;
    width: 260px !important;
    border-radius: 10px;
  }
}

.breakdown-container {
  margin-top: 10px;

  .breakdown-body {
    .breakdown-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 5px;

      .pop-text {
        font-size: 1rem;
        font-weight: bold;
        color:var(--lab-text-color-title-02);
      }

      .value-text {
        display: flex;
        align-items: center;
      
        span {
          font-size: 1rem;
          font-weight: bold;
          color:var(--lab-text-color-title-02);
          margin-right: 10px;
        }
      }
    }
  }
}

.text-font-size {
  font-size: 0.85rem !important;
}
</style>
