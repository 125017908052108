import { render, staticRenderFns } from "./G25Result.vue?vue&type=template&id=7cfb9603&scoped=true&"
import script from "./G25Result.vue?vue&type=script&lang=js&"
export * from "./G25Result.vue?vue&type=script&lang=js&"
import style0 from "./G25Result.vue?vue&type=style&index=0&id=7cfb9603&lang=css&scoped=true&"
import style1 from "./G25Result.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cfb9603",
  null
  
)

export default component.exports